import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomField } from '../model/customField';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

  private customFieldListUrl: string;
  public customFieldUrl: string;
  public generateCustomFieldsUrl: string;

  constructor(private httpClient: HttpClient, private config: Configuration) {
    this.customFieldListUrl = this.config.customFieldListApiUrl;
    this.customFieldUrl = this.config.customFieldApiUrl;
    this.generateCustomFieldsUrl = this.config.generateCustomFieldsApiUrl;
  }

  public getAllCustomFieldList<T>(): Observable<T> {
    return this.httpClient.get<T>(this.customFieldListUrl);
  }

  public getAllCustomFieldsByCompanyId<T>(companyId: string): Observable<T> {
    return this.httpClient.get<T>(this.customFieldUrl + '/' + companyId);
  }

  public addCustomField<T>(customField: CustomField): Observable<T> {
    return this.httpClient.post<T>(this.customFieldUrl, customField);
  }

  public updateCustomField<T>(customField: CustomField): Observable<T> {
    return this.httpClient.put<T>(this.customFieldUrl, customField);
  }
  public generateCustomFields<T>(companyId: string): Observable<T> {
    return this.httpClient.get<T>(this.generateCustomFieldsUrl + '/' + companyId);
  }
}
